$navigation: #dddddd;
$text: #333;
$scroll-bar-thumb: rgb(102, 102, 102);
$scroll-bar: #f4f4f4;
$primary: #00a7ef;
$warn: #fb1d09;
$line: #979797;
$moderate: #f7b500;
$strong: #6dd400;
$input-line: #dfe2e4;
$inactive-icon: #757575;
$password-strength: #7d7a77;
$moderate-password: #f7b500;
$strong-password: #f2f54e;
$complete-password: #62dd51;
$password-strength-background: #d8d8d8;
