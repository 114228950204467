@import './colors.scss';

.rs-blue-button {
  background-color: $primary;
  color: white;
  height: 50px;
  font-size: 20px;
  width: 100%;
  margin: 15px 0;
  text-transform: uppercase;

  &:disabled {
    color: #fff;
  }

}

.app__link {
  font-size: 16px !important;
  font-weight: bold !important;
  color: $primary !important;
  padding: 0 !important;
  line-height: normal !important;
  width: fit-content !important;
}
.app__link_huge {
  font-size: 18px;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
}

.rs-link {
  font-size: 14px;
  font-weight: bold;
  color: $primary;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.rs-link-button {
  font-size: 14px;
  font-weight: bold;
  color: $primary;
  padding-left: 0 !important;
}

.rs-top-label {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 30px;
}

.rs-middle-label {
  font-size: 24px;
  font-weight: 300;
}
