@import './colors.scss';

.rs-form-control-linear {
  ::ng-deep {
    .mat-form-field-appearance-legacy .mat-form-field-underline {
      height: 2px;
    }
  }
}

.rs-form-control-outline {

  mat-error {
    font-size: 14px;
  }

  ::ng-deep {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      height: 60px;
      padding-left: 20px;
    }
  }

  ::ng-deep {
    .mat-form-field-empty.mat-form-field-label,
    .mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
      color: #cdcdcd;
      font-size: 16px;
      position: relative;
      top: 15px;
    }
  }

}

.rs-form-select {
  ::ng-deep {

    .mat-select-arrow-wrapper {
      background-image: url('../../../assets/img/outline_expand.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      top: -5px;
    }

    .mat-select-arrow {
      width: 25px;
      height: 45px;
      border-top: none;
    }

  }
}

.mat-dialog-container {
  position: relative;
}

.rs-authentication-code-input {
  ::ng-deep {

    .mat-form-field-infix {
      height: 90px;
    }

    .mat-focused {
      .mat-form-field-appearance-legacy .mat-form-field-underline {

        background-color: red !important;
      }
    }

    .mat-form-field.mat-focused .mat-form-field-ripple{
      background-color: black;
    }

  }

  .mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
    height: 2px;
  }

}

.rs-phone-control {
  padding-top: 4px !important;

  .iti {
    width: 100%;

    input {
      height: 60px !important;
      width: 100%;
      margin-right: 0 !important;
      border-radius: 4px;
      border: 1px solid $input-line !important;
      font-size: 18px;
      outline: none;
      padding-left: 50px;
      color: $text;
      transition: .3s;

      &:hover {
        border-width: 2.22px !important;
        border-color: $text !important;
      }
      &:focus {
        border-width: 2.22px !important;
        border-color: $primary !important;
      }
      &.ng-invalid.ng-touched {
        border-width: 2.22px !important;
        border-color: $warn !important;
      }
    }
    &__flag-container {
      height: 60px !important;
    }
    &__selected-flag {
      outline: none;
    }
  }

  &_linear {
    padding-top: 0 !important;

    .iti {
      input {
        height: 50px !important;
        border: none !important;
        border-radius: 0px !important;
        border-bottom: 2.2px solid $input-line !important;
        margin-bottom: 17px !important;

        &:hover {
          border-color: $input-line !important;
        }
        &:focus {
          border-width: 2.22px !important;
          border-color: $primary !important;
        }
      }
      &__flag-container {
        height: 50px !important;
      }
    }
  }
}
